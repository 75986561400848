<template>
  <!-- Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <!-- left align -->
        <b-col
          md="6"
          lg="4"
        >
          <b-button 
            :to="{ name: 'integrations-stripe'}"
            v-b-hover="handleStripeHover" :variant="isStripeHovered ? 'outline-primary' : 'bg-transparent'"
          >
            <b-card
              style="min-width: 20rem; min-height: 17rem;"
              class="text-center mb-0"
            >
              <div class="profile-image p-0">
                <b-avatar
                  size="96"
                  variant="light"
                  :src="require('@/assets/images/icons/stripe.svg')"
                />
              </div>
              <h3 class="mt-2">Stripe</h3>
              <b-card-text>
                Use Stripe to accept payments from bookings.
              </b-card-text>
              
            </b-card>
          </b-button>
        </b-col>

        <!-- center align -->
        <b-col
          md="6"
          lg="4"
        >
          <b-button
            :to="{ name: 'integrations-linkedin' }"
            v-b-hover="handleLinkedinHover" :variant="isLinkedinHovered ? 'outline-primary' : 'bg-transparent'"
          >
            <b-card
              style="min-width: 20rem; min-height: 17rem;"
              class="text-center mb-0 w"
            >
              <div class="profile-image p-0">
                <b-avatar
                  size="96"
                  variant="light"
                  :src="require('@/assets/images/icons/linkedin.svg')"
                />
              </div>
              <h3 class="mt-2">LinkedIn</h3>
              <b-card-text>
                Connecting to Linkedin will give you more credibility as an expert.
              </b-card-text>
              
            </b-card>
          </b-button>
        </b-col>

        <!-- right align -->
        <b-col
          md="6"
          lg="4"
        >
          <b-button 
            :to="{ name: 'integrations-google' }"
            v-b-hover="handleGoogleHover" :variant="isGoogleHovered ? 'outline-primary' : 'bg-transparent'"
          >
            <b-card
              style="min-width: 20rem; min-height: 17rem;"
              class="text-center mb-0 w"
            >
              <div class="profile-image p-0">
                <b-avatar
                  size="96"
                  variant="light"
                  :src="require('@/assets/images/icons/googleCalendar.png')"
                />
              </div>
              <h3 class="mt-2">Google Calendar
              </h3>
              <b-card-text>
                Integrate your Google calendar with Hustleforge to automatically book meetings.
              </b-card-text>
              
            </b-card>
          </b-button>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col
          md="6"
          lg="4"
        >
          <b-button variant="bg-transparent">
            <b-card
              style="min-width: 20rem; min-height: 17rem;"
              class="text-center mb-0"
            >
              <div class="profile-image p-0">
                <b-avatar
                  size="96"
                  variant="light"
                  :src="require('@/assets/images/icons/zoom.svg')"
                />
              </div>
              <h3 class="mt-2">Zoom</h3>
              <b-card-text>
                Comming Soon
              </b-card-text>
              
            </b-card>
          </b-button>
        </b-col>
      </b-row> -->
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText, BButton, BAvatar, VBHover
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle, 
    BCardText,
    BButton,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-hover': VBHover
  },
  data() {
    return {
      isStripeHovered: false,
      isLinkedinHovered: false,
      isGoogleHovered: false
    }
  },
  methods: {
    handleStripeHover(hovered) {
      this.isStripeHovered = hovered
    },
    handleLinkedinHover(hovered) {
      this.isLinkedinHovered = hovered
    },
    handleGoogleHover(hovered) {
      this.isGoogleHovered = hovered
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

